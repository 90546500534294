<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn class="align-self-center" v-bind="attrs" icon dark v-on="on">
          <v-icon>
            mdi-translate
          </v-icon>
        </v-btn>
      </slot>
    </template>
    <v-list light>
      <v-list-item @click="changeLanguage('zh-TW')">
        繁體中文
      </v-list-item>
      <v-list-item @click="changeLanguage('en')">
        English
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'TranslationMenu',

  methods: {
    changeLanguage(lang) {
      this.$store.dispatch('changeLanguage', { lang })
    }
  }
}
</script>

<style>

</style>
