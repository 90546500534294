<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    :retain-focus="false"
    persistent
    hide-overlay
    no-click-animation
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <v-card
      min-height="200"
    >
      <v-card-title class="draggable bg-ease white--text">
        <v-icon left dark>
          mdi-content-save
        </v-icon>
        {{ $t('save_as_new_file') }}

        <v-spacer />

        <v-btn
          dark
          icon
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-3">
        <v-row
          v-if="breadcrumbs"
          no-gutters
          align="center"
        >
          <v-col
            class="
              mt-2
              mr-4
              d-flex
              align-stretch
              text-subtitle-2
            "
            cols="auto"
          >
            <v-row class="flex-nowrap" no-gutters align="center">
              <template v-for="(breadcrumb, iBreadcrumb) in breadcrumbs">
                <span
                  :key="iBreadcrumb"
                  class="text-no-wrap grey--text"
                  :class="{
                    'primary--text': iBreadcrumb===breadcrumbs.length-1,
                  }"
                >{{ breadcrumb }}<span v-if="iBreadcrumb===breadcrumbs.length-1">.glb</span></span>
                <v-icon
                  v-if="iBreadcrumb < breadcrumbs.length - 1"
                  :key="`${iBreadcrumb}-divder`"
                  color="grey"
                  small
                >
                  mdi-chevron-right
                </v-icon>
              </template>
            </v-row>
          </v-col>
        </v-row>
        <v-form
          ref="form"
          v-model="isValid"
          :disabled="isLoading"
        >
          <v-text-field
            v-model="formData.name"
            :label="$t('file_name')"
            filled
            suffix=".glb"
            :rules="rulesName"
          />

          <v-subheader class="py-0">
            {{ $t('page.map.save_file_content') }}
          </v-subheader>
          <v-input
            :value="formData.meshes"
            :rules="rulesMeshes"
          >
            <v-row>
              <v-col
                v-for="(mesh,iMesh) in meshes"
                :key="`${mesh.name}-${iMesh}`"
                cols="12"
              >
                <v-checkbox
                  v-model="formData.meshes"
                  :label="mesh.name"
                  :value="mesh"
                  dense
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-input>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-spacer />

        <v-btn
          class="action-btn"
          rounded
          color="primary"
          :loading="isLoading"
          @click="submit"
        >
          {{ $t('save') }}
        </v-btn>
        <v-btn
          class="action-btn"
          rounded
          :disabled="isLoading"
          @click="cancel"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validateRequired, validateDuplicate } from '@/assets/js/validate'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SaveDialog',

  data: () => ({
    dialog: false,
    isLoading: false,
    isValid: true,
    formData: {
      name: '',
      meshes: []
    }
  }),

  computed: {
    ...mapState({
      fileTree: state => state.files.fileTree,
      currentLayerNode: state => state.map.currentLayerNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    rulesName() {
      return [
        validateRequired,
        v => validateDuplicate(this.currentNames)(`${v}.glb`)
      ]
    },
    rulesMeshes() {
      return [
        v => ((!!v && !Array.isArray(v)) || !!v.length) || '至少勾選一個mesh儲存'
      ]
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    fileNode() {
      if (!this.currentLayerNode) {
        return
      }

      return this.fileTree.findNodeDF(
        this.fileTree.root,
        this.currentLayerNode.uuid
      )
    },
    currentNames() {
      if (!this.currentLayerNode) {
        return []
      }

      return this.fileNode?.parent.children.map(c => c.name) || []
    },
    meshes() {
      return this.currentLayerNode?.meshes || []
    },
    breadcrumbs() {
      if (!this.fileNode) {
        return []
      }

      let fileName = this.formData.name || ''
      if (fileName.length > 24) {
        fileName = `${fileName.slice(0, 12)}...${fileName.slice(-12)}`
      }
      const breadcrumbs = this.fileNode.breadcrumbs.slice(0, -1)
      breadcrumbs.push(fileName)

      return breadcrumbs
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    },
    dialog: {
      handler(newVal) {
        if (newVal) {
          this.init()
        }
      }
    },
    currentLayerNode: {
      handler(newVal) {
        this.init()
        if (!newVal) {
          this.close()
        }
      }
    }
  },

  methods: {
    init() {
      if (!this.currentLayerNode) {
        return
      }
      this.formData.meshes = this.meshes.slice()
      this.formData.name = this.getDefaultName()
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()
    },
    getDefaultName() {
      if (!this.fileNode) {
        return
      }

      const currentFileName = this.fileNode.name.split('.').slice(0, -1).join('.')
      let name = ''
      let index = 1
      do {
        name = `${currentFileName} (${index++})`
      } while (this.currentNames.includes(`${name}.glb`))

      return name
    },
    fetchMapsetChildren() {
      const mapset = this.fileNode?.parent

      if (!mapset) {
        return Promise.resolve()
      }

      return this.$store.dispatch('files/fetchFileNodes', {
        project: this.project,
        parent: mapset
      })
    },
    async submit() {
      await this.$refs.form.validate()

      if (!this.isValid) {
        return
      }

      this.isLoading = true
      await this.fetchMapsetChildren()

      if (!this.isValid) {
        this.isLoading = false
        return this.$store.dispatch('snackbar/showError', {
          content: this.$t('validate.filename_duplicate')
        })
      }

      const settings = this.currentLayerNode.stagingFile
        ? this.currentLayerNode.stagingFile.settings
        : this.currentLayerNode.settings
      const plotSettings = settings.plot.legends
        .map(legend => {
          const activeScalarsName = legend.scalarsName
          const vtkMeshPairs = this.formData.meshes
            .filter(mesh => mesh.settings.activeScalarsName === activeScalarsName)
            .map(mesh => ({
              mesh_uuid: mesh.name,
              vtk_resource_uuid: mesh.settings.parentVtk
            }))
          const rangeMin = parseFloat(legend.rangeMin)
          const rangeMax = parseFloat(legend.rangeMax)
          return {
            active_scalars_name: legend.scalarsName,
            colormap_name: legend.colormapName,
            colormap_level: parseInt(legend.colormapLevel),
            range_min: Number.isNaN(rangeMin) ? null : rangeMin,
            range_max: Number.isNaN(rangeMax) ? null : rangeMax,
            vtk_mesh_pairs: vtkMeshPairs
          }
        })
        .filter(plotSetting => plotSetting.vtk_mesh_pairs.length)

      const meshNames = this.formData.meshes.map(mesh => mesh.name)
      return this.$store.dispatch('postprocess/save', {
        project: this.project,
        fileNode: this.fileNode,
        layerNode: this.currentLayerNode,
        glbFileName: this.formData.name,
        plotSettings,
        settings: {
          ...settings,
          // 只留下有勾選的mesh
          meshes: settings.meshes.filter(mesh => meshNames.includes(mesh.name))
        }
      })
        .then(fileNodeWithContent => {
          this.$store.dispatch('map/removeNode', {
            node: this.currentLayerNode
          })
          this.$emit('fileContentLoaded', {
            fileNode: fileNodeWithContent
          })

          this.close()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
